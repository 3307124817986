import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/node_modules/.pnpm/@next+third-parties@14.2.2_next@14.2.0_react@18.2.0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/node_modules/.pnpm/@next+third-parties@14.2.2_next@14.2.0_react@18.2.0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/node_modules/.pnpm/@next+third-parties@14.2.2_next@14.2.0_react@18.2.0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/web-app/web-app/node_modules/.pnpm/next-intl@3.14.1_next@14.2.0_react@18.2.0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/web-app/web-app/node_modules/.pnpm/next@14.2.0_@babel+core@7.24.7_@opentelemetry+api@1.7.0_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/node_modules/.pnpm/next@14.2.0_@babel+core@7.24.7_@opentelemetry+api@1.7.0_react-dom@18.2.0_react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Syne\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-heading\"}],\"variableName\":\"fontHeading\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ZealyQueryProvider","queryClient"] */ "/home/runner/work/web-app/web-app/packages/webapp/app/QueryProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieBanner"] */ "/home/runner/work/web-app/web-app/packages/webapp/components/CookieBanner/CookieBanner.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/packages/webapp/components/DatadogInit/DatadogInit.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/packages/webapp/components/InitRewardful/InitRewardful.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NextIntlProvider"] */ "/home/runner/work/web-app/web-app/packages/webapp/components/NextIntlProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SourceTracker"] */ "/home/runner/work/web-app/web-app/packages/webapp/components/SourceTracker/SourceTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/home/runner/work/web-app/web-app/packages/webapp/components/theme-provider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/packages/webapp/components/Toaster/Toaster.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/packages/webapp/context/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthErrorProvider"] */ "/home/runner/work/web-app/web-app/packages/webapp/context/AuthError.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CommunityLayoutVariantProvider"] */ "/home/runner/work/web-app/web-app/packages/webapp/context/CommunityLayoutVariant.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/packages/webapp/context/CookiePreferences.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeatureFlagsProvider"] */ "/home/runner/work/web-app/web-app/packages/webapp/context/FeatureFlags/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/packages/webapp/context/ReCaptcha.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/packages/webapp/context/SupportChat/SupportChat.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/packages/webapp/features/Popups/PopupProvider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/packages/webapp/styles/globals.css");
